<template>
  <div class="container">
    <h4 class="text-center my-4">KPI преподавателя</h4>
    <div class="text-center">
      <p> <strong>{{urlLastname}} {{urlFirstname}}</strong></p>
      <p><strong>{{urlYear}}</strong></p>
      <p><strong>{{urlMonth}}</strong></p>
    </div>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3">

      <table class="table table-bordered">
        <thead>
        <tr>
          <th scope="col">№</th>
          <th scope="col">Наименование</th>
          <th scope="col">Единица измерения</th>
          <th scope="col">Плановое значение</th>
          <th scope="col">Фактическое значение</th>
          <th scope="col" style="width: 150px" v-if="decryptCheckRole('kpiCommission') || decryptCheckRole('admin')">Баллы</th>
        </tr>
        </thead>
        <tbody v-for="(kType, kTypeIndex) in kpiType" :key="kTypeIndex">

        <tr class="table-success">
          <th colspan="6">{{ kTypeIndex + 1 }}. {{ kType.name }}</th>
        </tr>

        <tr v-for="(kSubType, kSubTypeIndex) in kpiSubType.filter(i=>i.kpi_type_id==kType.id)" :key="kSubTypeIndex">
          <td>{{ kTypeIndex + 1 }}.{{ kSubTypeIndex + 1 }}</td>
          <td>{{ kSubType.name }}</td>
          <td>количество</td>
          <td>
            {{kSubType.value_plan }}
          </td>
          <td>
<!--            {{kSubType.static_score }}-->
            {{userKpi[kSubType.id]?.count > 0 ? kSubType.static_score : 0}}
          </td>
          <td>
<!--            <input class="form-control" type="number" max="100" min="0" placeholder="Общий балл"-->
<!--                     v-model="total_score">-->
            <div v-if="decryptCheckRole('kpiCommission') || decryptCheckRole('admin')">
<!--              <Button :label="kpiCommission_form.teacherScore?.find(i=>i.kpi_sub_type_id == kSubType.id)?.score" @click="openAddScoreDialog()"/>-->
              <div v-if="kpiCommission_form.teacherScore?.find(i=>i.kpi_sub_type_id == kSubType.id)?.score">
                {{kpiCommission_form.teacherScore?.find(i=>i.kpi_sub_type_id == kSubType.id)?.score}}
              </div>
              <div v-else>
                <Button label="Оценка" @click="openUpdateScoreDialog(kSubType.id)"/>
              </div>

            </div>

          </td>
        </tr>

        </tbody>
      </table>


      <table class="table table-striped">
        <tbody>
        <tr class="total table-success">
          <td class="text-center">Общий балл</td>
        </tr>
        <tr>
          <td class="text-center">{{totalScore()}}</td>
        </tr>
        </tbody>
      </table>


      <Dialog :header="'Поставить оценку'" :visible="updateScoreDisplay" :breakpoints="{'960px': '75vw'}" :style="{width: '50vw'}" :closable="false">


        <div class="row mt-4">
          <div class="col-md-3">
            <p>Оценка</p>
          </div>
          <div class="col-md-9">
            <input class="form-control" type="number" placeholder="Оценка"
                   @change="changeScore($event)">
          </div>
        </div>

        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" @click="closeUpdateScoreDialog" class="p-button-text"/>
          <Button label="Сохранить" icon="pi pi-check" :loading="btnSaveDisabled" @click="submitScore"/>
        </template>
      </Dialog>








    </div>





  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import {decryptCheckRole} from "@/utils/helpers/decryptedRoles.helpers";
export default {
  name: 'KpiCommissionTeacher',
  data() {
    return {
      loading: true,
      urlUserId: +this.$route.query.user_id || 0,
      urlLastname: this.$route.query.lastname || '',
      urlFirstname: this.$route.query.firstname || '',
      urlMonth: this.$route.query.month || '',
      urlYear: this.$route.query.year || '',
      updateScoreDisplay: false,
      score: null,
      kpi_sub_type_id: null,
      btnSaveDisabled: false,
    }
  },
  computed: {
    ...mapState('kpiCommission', ['kpiCommission_form']),
    ...mapState('kpiTeacher', ['kpiType', 'kpiSubType', 'userKpi'])
  },

  methods: {
    decryptCheckRole,

    ...mapActions('kpiCommission', ['GET_KPI_TEACHER_SCORE','POST_USER_KPI_COMMISSION_DECISION']),
    ...mapActions('kpiTeacher', ['GET_KPI_TYPE', 'GET_KPI_SUB_TYPE', 'GET_USER_KPI']),

    //...mapMutations('userinfo', ['SET_USER_ID']),

    totalScore() {
      return this.kpiCommission_form.teacherScore.reduce((sum, i) => +i.score + sum, 0)
    },

    // checkRole(i) {
    //   const roles = JSON.parse(getCookie('ROLES')) || []
    //   if (!roles.length && getCookie('ROLE')) {
    //     roles.push(getCookie('ROLE'))
    //   }
    //   return roles.includes(i)
    // },

    openUpdateScoreDialog(kpi_sub_type_id) {
      this.updateScoreDisplay = true
      this.kpi_sub_type_id = kpi_sub_type_id
    },
    closeUpdateScoreDialog() {
      this.updateScoreDisplay = false
      this.score = null
    },
    changeScore(e) {
      this.score = e.target.value
      console.log(this.score, 'score')
    },

    async submitScore() {
      this.updateScoreDisplay = false
      this.btnSaveDisabled = true

      let form = {}
      form['user_id'] = this.urlUserId
      form['kpi_sub_type_id'] = this.kpi_sub_type_id
      form['score'] = this.score
      // form.push({
      //   user_id: this.urlUserId,
      //   kpi_sub_type_id: this.kpi_sub_type_id,
      //   score: this.score
      // })
      console.log(form, 'submit post form')
      const postRes = await this.POST_USER_KPI_COMMISSION_DECISION(form)
      if (postRes) {
        await this.GET_KPI_TEACHER_SCORE(this.urlUserId)
        this.$message({title: 'Успешно сохранено'})
        this.score = null
        this.kpi_sub_type_id = null
      }
      this.btnSaveDisabled = false


    },

  },
  async mounted() {
    await this.GET_USER_KPI(this.urlUserId)
    await this.GET_KPI_TEACHER_SCORE(this.urlUserId)
    await this.GET_KPI_TYPE()
    await this.GET_KPI_SUB_TYPE()
    this.loading = false
  },
}

</script>